import React, { Component } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { injectIntl } from 'react-intl'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import {
	faUsers,
	faMusic,
	faHdd,
	faVial,
	faMobile
} from '@fortawesome/free-solid-svg-icons'
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons'

import * as Auth from './auth'
import Login from './views/auth/login'
import DevicesTable from './views/devices/DevicesTable'
import DeviceDetails from './views/devices/DeviceDetails'
import ThirdPartyDevicesTable from './views/thirdPartyDevices/ThirdPartyDevicesTable'
import ThirdPartyDeviceDetails from './views/thirdPartyDevices/ThirdPartyDeviceDetails'
import ProgramsTable from './views/programs/ProgramsTable'
import ProgramDetails from './views/programs/ProgramDetails'
import MessageProgramsTable from './views/messages/MessageProgramsTable'
import MessageProgramDetails from './views/messages/MessageProgramDetails'
import TeamsTree from './views/teams/TeamsTree'
import TeamDetails from './views/teams/TeamDetails'
import SamplesTable from './views/samples/SamplesTable'
import SampleDetails from './views/samples/SampleDetails'
import ForgotPassword from './views/auth/forgotPassword'
import ResetPassword from './views/auth/resetPassword'
import Loading from './components/LoadingPage'
import MasterDetail from './components/MasterDetail'
import MartiniPairDevice from './views/devices/MartiniPairDevice'
import SonosPairDevice from './views/devices/SonosPairDevice'
import Error from './components/Error'
import Listen from './views/samples/Listen/Listen'

const AccessRoute = ({ component: Component, render, access, ...rest }) => {
	if (access && !Auth.hasAccess(access, Auth.cache.user.teamId)) {
		return (
			<Redirect
				to={{
					pathname: `/error/401/${encodeURIComponent(rest.location.pathname)}`,
					state: { from: rest.location }
				}}
			/>
		)
	}

	if (render === null) {
		return <Component {...rest} />
	} else {
		return render(rest)
	}
}

class IPrivateRouteContent extends Component {
	state = {
		loading: this.props.loading || !Auth.cache.user,
		error: null
	}

	componentDidMount() {
		if (!this.props.loading && !Auth.cache.user) {
			this.getMe()
		}
	}

	getMe = async () => {
		try {
			await Auth.refreshUser(this.props.client)
			this.props.setLanguage(Auth.cache.user.language)
			this.setState({ loading: false })
		} catch (e) {
			this.setState({
				loading: false,
				error: this.props.intl.formatMessage({ id: 'error.message' })
			})
		}
	}

	render() {
		if (this.state.loading) {
			return <Loading />
		}
		if (!!Auth.cache.user) {
			return <AccessRoute {...this.props} />
		}
		if (this.state.error) {
			return (
				<Redirect
					to={{
						pathname: `/login/${encodeURIComponent(this.props.location.pathname)}`,
						state: { from: this.props.location }
					}}
				/>
			)
		}
		return <AccessRoute {...this.props} />
	}
}

const PrivateRouteContent = injectIntl(withApollo(IPrivateRouteContent))

export default class App extends Component {
	render() {
		window.Intercom('update')
		return (
			<BrowserRouter>
				<Switch>
					<Route
						path="/error/:code/:error"
						render={(props) => <Error hideMenu {...props} />}
					/>
					<Route path="/success" render={(_) => <Loading />} />
					<Route
						path="/login/:from"
						render={(props) => <Login {...props} />}
					/>
					<Route
						path="/login"
						render={(props) => <Login {...props} />}
					/>
					<Route
						exact
						path="/forgot_password"
						render={(props) => <ForgotPassword {...props} />}
					/>
					<Route
						path="/reset_password/:username/:token/:new"
						render={(props) => <ResetPassword {...props} />}
					/>
					<Route
						path="/devices/add/:pin"
						render={(props) => (
							<PrivateRouteContent
								setLanguage={this.props.setLanguage}
								render={(props) => (
									<MartiniPairDevice {...props} />
								)}
								access="device:add"
								{...props}
							/>
						)}
					/>
					<Route
						path="/devices/sonos/:devId/:uuid"
						render={(props) => (
							<PrivateRouteContent
								setLanguage={this.props.setLanguage}
								render={(props) => (
									<SonosPairDevice {...props} />
								)}
								access="thirdPartyDevice:add"
								{...props}
							/>
						)}
					/>
					<Route
						path="/devices"
						render={(props) => (
							<PrivateRouteContent
								setLanguage={this.props.setLanguage}
								render={(props) => (
									<MasterDetail
										{...props}
										master={DevicesTable}
										detail={DeviceDetails}
										icon={faHdd}
										itemVariable="deviceId"
									/>
								)}
								{...props}
							/>
						)}
					/>
					<Route
						path="/thirdpartydevices"
						render={(props) => (
							<PrivateRouteContent
								setLanguage={this.props.setLanguage}
								render={(props) => (
									<MasterDetail
										{...props}
										master={ThirdPartyDevicesTable}
										detail={ThirdPartyDeviceDetails}
										icon={faMobile}
										itemVariable="thirdPartyDeviceId"
									/>
								)}
								{...props}
							/>
						)}
					/>
					<Route
						path="/programs"
						render={(props) => (
							<PrivateRouteContent
								setLanguage={this.props.setLanguage}
								render={(props) => (
									<MasterDetail
										{...props}
										master={ProgramsTable}
										detail={ProgramDetails}
										icon={faMusic}
										itemVariable="programId"
									/>
								)}
								{...props}
							/>
						)}
					/>
					<Route
						path="/messages"
						render={(props) => (
							<PrivateRouteContent
								setLanguage={this.props.setLanguage}
								render={(props) => (
									<MasterDetail
										{...props}
										master={MessageProgramsTable}
										detail={MessageProgramDetails}
										icon={faCommentAlt}
										itemVariable="messageProgramId"
									/>
								)}
								{...props}
							/>
						)}
					/>
					<Route
						path="/teams/notifications"
						render={(props) => (
							<PrivateRouteContent
								setLanguage={this.props.setLanguage}
								render={(props) => (
									<MasterDetail
										{...props}
										master={TeamsTree}
										detail={TeamDetails}
										icon={faUsers}
										itemVariable="teamId"
									/>
								)}
								{...props}
							/>
						)}
					/>
					<Route
						path="/teams"
						render={(props) => (
							<PrivateRouteContent
								setLanguage={this.props.setLanguage}
								render={(props) => (
									<MasterDetail
										{...props}
										master={TeamsTree}
										detail={TeamDetails}
										icon={faUsers}
										itemVariable="teamId"
									/>
								)}
								{...props}
							/>
						)}
					/>
					<Route
						path="/samples"
						render={(props) => (
							<PrivateRouteContent
								setLanguage={this.props.setLanguage}
								render={(props) => (
									<MasterDetail
										{...props}
										master={SamplesTable}
										detail={SampleDetails}
										icon={faVial}
										itemVariable="sampleId"
									/>
								)}
								{...props}
							/>
						)}
					/>
					<Route
						path="/listen/:sampleId"
						render={(props) => <Listen {...props} />}
					/>
					<Route
						path="/review/:sampleId"
						render={(props) => <Listen {...props} review />}
					/>
					<Redirect to="/devices" />
				</Switch>
			</BrowserRouter>
		)
	}
}
