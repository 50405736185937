import React, { Component } from 'react'
import { injectIntl, FormattedDate } from 'react-intl'
import { faPlus, faListUl, faHdd } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Table from '../../components/TableV2'
import NewProgram from './NewProgram'
import { setPageTitle } from '../../components/Utils'
import { getDateFromObjectIdString } from '../../utils/date'
import * as Auth from '../../auth'

class IProgramsListDisplay extends Component {
	columns = {
		playlists: {
			name: this.props.intl.formatMessage({ id: 'playlists' }),
			title: <FontAwesomeIcon icon={faListUl} />,
			queryProps: ['programPlaylistIds'],
			accessor: (record) => {
				return record?.programPlaylistIds?.length || 0
			},
			type: 'Number'
		},
		devices: {
			name: this.props.intl.formatMessage({ id: 'devices' }),
			title: <FontAwesomeIcon icon={faHdd} />,
			queryProps: ['devicesCount'],
			accessor: (record) => {
				if (!record.devicesCount) {
					return 0
				}
				return record.devicesCount
			},
			type: 'Number'
		},
		updated: {
			accessor: (program) => program.updatedAt,
			queryProps: ['updatedAt'],
			name: this.props.intl.formatMessage({
				id: 'programs.table.columns.updated'
			}),
			render: (program) =>
				!!program.updatedAt ?
					<FormattedDate
						value={program.updatedAt}
						year="numeric"
						month="2-digit"
						day="2-digit"
					/>
				:	'',
			type: 'Date'
		},
		created: {
			accessor: (program) => program._id,
			queryProps: ['_id'],
			name: this.props.intl.formatMessage({
				id: 'programs.table.columns.created'
			}),
			render: (program) => (
				<FormattedDate
					value={getDateFromObjectIdString(program._id)}
					year="numeric"
					month="2-digit"
					day="2-digit"
				/>
			),
			type: 'Date'
		}
	}

	render() {
		const {
			redirect,
			link,
			match,
			selectedId,
			onSelect,
			defaultColumns,
			defaultMobileColumns,
			storageKey,
			title,
			actions,
			data
		} = this.props
		let sel = null
		if (selectedId) {
			sel = selectedId
		} else if (match && match.params && match.params.programId) {
			sel = match.params.programId
		}

		return (
			<Table
				rowKey="_id"
				columns={this.columns}
				defaultColumns={defaultColumns}
				defaultMobileColumns={defaultMobileColumns}
				link={link}
				path="/programs"
				teamProp={(record) => record.team}
				nameProp={(record) => record.name}
				nameQueryProps={['name']}
				searchProps={(record) => [record.name]}
				selectedId={sel}
				redirect={redirect}
				onSelect={onSelect}
				storageKey={storageKey}
				match={match}
				queryType="programs"
				title={title}
				actions={actions}
				data={data}
			/>
		)
	}
}

export const ProgramsListDisplay = injectIntl(IProgramsListDisplay)

class ProgramsList extends Component {
	state = {
		newProgramModal: false
	}

	render() {
		const { match, intl } = this.props
		if (!match.params || !match.params.programId) {
			setPageTitle(intl, 'programs')
		}

		let actions = []
		if (Auth.hasAccess('program:add', Auth.cache.user.teamId)) {
			actions.push({
				title: intl.formatMessage({
					id: 'programs.actions.newProgram'
				}),
				key: 'newProgram',
				icon: faPlus,
				action: () => this.setState({ newProgramModal: true })
			})
		}

		return (
			<React.Fragment>
				<ProgramsListDisplay
					actions={actions}
					title={intl.formatMessage({ id: 'programs.table.title' })}
					match={match}
					redirect
					defaultColumns={['name', 'team', 'playlists']}
					defaultMobileColumns={['name', 'playlists']}
					extraQueryProps={[]}
					storageKey="programsList"
				/>
				{this.state.newProgramModal && (
					<NewProgram
						team={Auth.cache.team}
						onClose={(e) =>
							this.setState({ newProgramModal: false })
						}
					/>
				)}
			</React.Fragment>
		)
	}
}

export default injectIntl(ProgramsList)
