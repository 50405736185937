import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import {
	Alert,
	Button,
	Divider,
	Tabs,
	Tag,
	Modal,
	Timeline,
	message
} from 'antd'
import { Link, Redirect } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faCalendarAlt,
	faHdd,
	faTrash,
	faEdit,
	faUsers,
	faClipboard,
	faCopy,
	faSyncAlt,
	faPlus,
	faComments
} from '@fortawesome/free-solid-svg-icons'
import PlusOutlined from '@ant-design/icons/PlusOutlined'

import { DeviceListDisplay } from '../devices/DevicesTable'
import PlaylistDetails from './PlaylistDetails'
import Loading from '../../components/LoadingPage'
import config from '../../config'
import Title from '../../components/Title'
import TextInputModal from '../../components/TextInputModal'
import NewProgramModal from './NewProgram'
import { SelectTeamModal } from '../teams/SelectTeam'
import SchedulerV3 from './SchedulerV3'
import S, { Colors } from '../../components/Styles'
import {
	setPageTitle,
	programStats,
	displayGraphQLErrors
} from '../../components/Utils'
import {
	ProgramsTableQuery,
	ProgramDetailsQuery,
	ProgramDetailsDevicesQuery
} from './Queries'
import { DeviceActionMutation } from '../devices/Queries'
import * as Auth from '../../auth'
import * as Utils from '../../components/Utils'
import { getDateFromObjectIdString } from '../../utils/date'
import Comments from '../../components/CommentsPage'
import EditPlaylistDetails from './EditPlaylistDetails'
import SelectZones from '../devices/SelectZones'

const TabPane = Tabs.TabPane

class IProgramDetails extends Component {
	state = {
		renameModal: false,
		selectTeamModal: false,
		newPlaylistModal: false,
		duplicateModal: false,
		addDevicesModal: false
	}

	syncAll = async (zones) => {
		for (let zone of zones) {
			if (zone && zone.device) {
				try {
					const r = await this.props.client.mutate(
						DeviceActionMutation(zone.device, zone, 'sync')
					)
					Utils.parseMutationResponse(r)
				} catch (e) {
					Utils.displayError(e)
				}
			}
		}
	}

	// TODO: make a modal for options repeatDay and weight
	upgradeProgramSchedule = async () => {
		const { program, intl } = this.props
		Modal.confirm({
			title: intl.formatMessage({
				id: 'programs.scheduler.migrate.message'
			}),
			okText: intl.formatMessage({ id: 'ok' }),
			cancelText: intl.formatMessage({ id: 'actions.cancel' }),
			onOk: async () => {
				try {
					const r = await this.props.client.mutate({
						variables: {
							id: program._id,
							weight: 3
						},
						mutation: gql`
							mutation upgradeProgramSchedule(
								$id: String!
								$repeatDay: Boolean
								$weight: Float
							) {
								programUpgradeSchedule(
									id: $id
									manifestVersion: 3
									repeatDay: $repeatDay
									weight: $weight
								) {
									success
									message
								}
							}
						`,
						refetchQueries: [
							{
								query: ProgramDetailsQuery,
								variables: { id: program._id }
							}
						],
						awaitRefetchQueries: true
					})
					Utils.parseMutationResponse(r)
				} catch (e) {
					Utils.displayError(e)
				}
			}
		})
	}

	render() {
		const { program, intl, client } = this.props
		const programCreationDate = getDateFromObjectIdString(program._id)
		setPageTitle(intl, null, program.name)

		let actions = []
		if (
			Auth.hasPermission('program:edit:setName') &&
			Auth.hasAccess('program:edit', program.teamId)
		) {
			actions.push({
				title: intl.formatMessage({ id: 'actions.rename' }),
				key: 'renameProgram',
				icon: faEdit,
				action: () => this.setState({ renameModal: true })
			})
		}
		if (Auth.hasAccess('program:add', program.teamId)) {
			actions.push({
				title: intl.formatMessage({ id: 'programs.actions.duplicate' }),
				key: 'duplicate',
				icon: faCopy,
				action: () => this.setState({ duplicateModal: true })
			})
		}
		if (
			Auth.hasPermission('program:edit:setTeam') &&
			Auth.hasAccess('program:edit', program.teamId)
		) {
			actions.push({
				title: intl.formatMessage({ id: 'actions.assignToTeam' }),
				key: 'assignProgram',
				icon: faUsers,
				action: () => this.setState({ selectTeamModal: true })
			})
		}
		if (Auth.hasAccess('program:delete', program.teamId)) {
			actions.push({
				title: intl.formatMessage({ id: 'actions.delete' }),
				key: 'deleteProgram',
				icon: faTrash,
				confirm: intl.formatMessage(
					{ id: 'programs.actions.delete.confirm' },
					{ name: program.name }
				),
				mutation: {
					variables: { id: program._id },
					mutation: gql`
						mutation removeProgram($id: String!) {
							programRemoveById(id: $id) {
								success
								message
							}
						}
					`,
					refetchQueries: [
						{
							query: ProgramsTableQuery
						},
						{
							query: ProgramDetailsQuery,
							variables: { id: program._id }
						}
					],
					awaitRefetchQueries: true
				}
			})
		}

		const stats = programStats(program)

		return (
			<div>
				<Title
					title={program.name}
					actions={actions}
					backUrl="/programs"
					backTitle="programs"
				>
					<div style={S.itemDetails.teamPath}>
						{program.team && (
							<Link to={`/teams/${program.team._id}`}>
								<Tag color="blue">{program.team.path}</Tag>
							</Link>
						)}
					</div>
				</Title>

				<Tabs>
					<TabPane
						tab={
							<span>
								<FontAwesomeIcon icon={faCalendarAlt} />{' '}
								<FormattedMessage id="playlists" /> (
								{program.playlists ?
									program.playlists.length
								:	0}
								)
							</span>
						}
						key="1"
					>
						{program.manifestVersion !== 3 &&
							Auth.hasPermission(
								'program:edit:upgradeSchedule'
							) &&
							Auth.hasAccess('program:edit', program.teamId) && (
								<Alert
									type="info"
									showIcon
									message={intl.formatMessage({
										id: 'programs.scheduler.migrate.title'
									})}
									description={
										<div>
											<FormattedMessage id="programs.scheduler.migrate.message" />
											<div
												style={{ textAlign: 'center' }}
											>
												<Button
													style={{ marginTop: 10 }}
													type="primary"
													onClick={
														this
															.upgradeProgramSchedule
													}
												>
													<FormattedMessage id="programs.scheduler.migrate.yes" />
												</Button>
											</div>
										</div>
									}
									style={{ marginBottom: 20 }}
								/>
							)}
						<SchedulerV3 program={program} />

						{program.playlists &&
							program.playlists.map((p, i) => (
								<PlaylistDetails
									key={p._id}
									playlist={p}
									color={Colors.playlistColors[i]}
									program={program}
									playlistStats={stats[p._id]}
									genres={stats.genres}
								/>
							))}
						{Auth.hasAccess('programPlaylist:add', program.teamId) ?
							<Button
								onClick={() =>
									this.setState({ newPlaylistModal: true })
								}
								style={{ height: 170 + 56, width: '100%' }}
								icon={<PlusOutlined />}
								type="dashed"
							>
								<FormattedMessage id="playlists.actions.add" />
							</Button>
						: !program.playlists || program.playlists.length === 0 ?
							<div style={S.empty}>
								<FormattedMessage id="playlists.details.empty" />
							</div>
						:	null}
					</TabPane>
					{Auth.hasAccess('device:browse', 'all') && (
						<TabPane
							tab={
								<span>
									<FontAwesomeIcon icon={faHdd} />{' '}
									<FormattedMessage id="devices" />
								</span>
							}
							key="2"
						>
							<Query
								pollInterval={config.longPollInterval}
								query={ProgramDetailsDevicesQuery}
								variables={{ id: program._id }}
							>
								{({ loading, error, data }) => {
									if (data && data.programById) {
										return (
											<React.Fragment>
												{Auth.hasPermission(
													'device:edit:syncContent'
												) &&
													Auth.hasAccess(
														'device:edit',
														program.teamId
													) && (
														<Button
															style={{
																marginBottom: 10
															}}
															onClick={() =>
																this.syncAll(
																	data
																		.programById
																		.zones
																)
															}
														>
															<FontAwesomeIcon
																icon={faSyncAlt}
															/>
															&nbsp;
															<FormattedMessage id="programs.details.syncAllDevices" />
														</Button>
													)}
												{Auth.hasPermission(
													'zone:edit:setProgram'
												) && (
													<Button
														style={{
															marginBottom: 10,
															marginLeft: 10
														}}
														onClick={() =>
															this.setState({
																addDevicesModal: true
															})
														}
													>
														<FontAwesomeIcon
															icon={faPlus}
														/>
														&nbsp;
														<FormattedMessage id="programs.details.addDevices" />
													</Button>
												)}
												<DeviceListDisplay
													data={data.programById.zones.map(
														(z) => {
															if (
																z.device &&
																z.device._id
															) {
																return {
																	...z.device,
																	zone: {
																		_id: z._id,
																		name: z.name
																	}
																}
															}
															return null
														}
													)}
													defaultColumns={[
														'name',
														'status',
														'sync',
														'os',
														'zone'
													]}
													link
												/>
												{this.state.addDevicesModal && (
													<SelectZones
														title={intl.formatMessage(
															{
																id: 'programs.details.addDevices.title'
															},
															{
																program:
																	program.name
															}
														)}
														selected={data.programById.zones.map(
															(z) => z._id
														)}
														teams={[program.teamId]}
														onClose={(_) =>
															this.setState({
																addDevicesModal: false
															})
														}
														property="program"
														query={gql`
															query fetchProgramDetailTeam(
																$teams: [String!]!
															) {
																teams: teamByIds(
																	_ids: $teams
																) {
																	_id
																	n
																	devices {
																		_id
																		name
																		nickname
																		zones {
																			_id
																			name
																			program {
																				_id
																				name
																			}
																		}
																	}
																}
															}
														`}
														onSave={async (
															zoneIds,
															removeZoneIds,
															sync
														) => {
															try {
																const r =
																	await client.mutate(
																		{
																			variables:
																				{
																					id: program._id,
																					zoneIds,
																					removeZoneIds,
																					sync
																				},
																			mutation: gql`
																				mutation programAssignToDevices(
																					$id: String!
																					$zoneIds: [String]!
																					$removeZoneIds: [String]
																					$sync: Boolean
																				) {
																					programAssignToDevices(
																						id: $id
																						zoneIds: $zoneIds
																						removeZoneIds: $removeZoneIds
																						sync: $sync
																					) {
																						success
																						message
																					}
																				}
																			`,
																			refetchQueries:
																				[
																					{
																						query: ProgramDetailsDevicesQuery,
																						variables:
																							{
																								id: program._id
																							}
																					}
																				],
																			awaitRefetchQueries: true
																		}
																	)
																Utils.parseMutationResponse(
																	r
																)
															} catch (e) {
																Utils.displayError(
																	e
																)
															}
														}}
													/>
												)}
											</React.Fragment>
										)
									}
									if (loading) {
										return <Loading />
									}
									if (error) {
										return (
											<Alert
												message={this.props.intl.formatMessage(
													{ id: 'error' }
												)}
												description={displayGraphQLErrors(
													error
												)}
												type="error"
												showIcon
											/>
										)
									}

									return null
								}}
							</Query>
						</TabPane>
					)}
					{
						// Will enable bucket selection when we have multiple normalization methods
						// Right now MPD can't read most originals or platinum files
						// Auth.hasPermission('programPlaylist:edit:setMontyPlaylist') && Auth.hasAccess('programPlaylist:edit', program.teamId) &&
						// <TabPane tab={<span><FontAwesomeIcon icon={faCog} /> <FormattedMessage id='settings' /></span>} key='3'>
						// 	<Form>
						// 		<FormItem {...formItemLayout} label={intl.formatMessage({ id: "playlists.details.bucket" })}>
						// 			<RadioGroup value={program.bucket || 0} onChange={v => {
						// 				try {
						// 					client.mutate({
						// 						variables: {id: program._id, date: new Date(), bucket: v.target.value},
						// 						mutation: gql`
						// 							mutation program($id: String!, $bucket: Float!, $date: Date!) {
						// 								programUpdateById(_id: $id, record: {
						// 									bucket: $bucket,
						// 									updated: $date
						// 								}) {
						// 									recordId
						// 								}
						// 							}
						// 						`,
						// 						refetchQueries: [{
						// 							query: ProgramDetailsQuery,
						// 							variables: {id: program._id}
						// 						}]
						// 					})
						// 				} catch(e) {
						// 					console.log(e.message)
						// 				}
						// 			}}>
						// 				<RadioButton value={0}><FormattedMessage id='playlists.details.bucket.optimized' /></RadioButton>
						// 				<RadioButton value={1}><FormattedMessage id='playlists.details.bucket.originals' /></RadioButton>
						// 				<RadioButton value={2}><FormattedMessage id='playlists.details.bucket.platinum' /></RadioButton>
						// 			</RadioGroup>
						// 		</FormItem>
						// 	</Form>
						// </TabPane>
					}
					{Auth.hasPermission('program:read:note') && (
						<TabPane
							tab={
								<span>
									<FontAwesomeIcon icon={faClipboard} />{' '}
									<FormattedMessage id="spectreNotes" />
								</span>
							}
							key="4"
						>
							<Timeline mode="left" style={{ marginTop: 20 }}>
								<Timeline.Item
									color="green"
									label={programCreationDate.toLocaleString()}
								>
									Created
								</Timeline.Item>
								<Timeline.Item
									label={new Date(
										program.updatedAt
									).toLocaleString()}
								>
									Last updated
								</Timeline.Item>
							</Timeline>
							<Divider orientation="left">
								<FontAwesomeIcon icon={faComments} /> Comments
							</Divider>
							<Comments
								objectId={program._id}
								writePermission="program:edit:setNote"
							/>
						</TabPane>
					)}
				</Tabs>

				{this.state.renameModal && (
					<TextInputModal
						initialValue={program.name}
						title={intl.formatMessage({
							id: 'programs.actions.renameProgram'
						})}
						actionLabel={intl.formatMessage({
							id: 'actions.rename'
						})}
						placeholder={intl.formatMessage({ id: 'program' })}
						onClose={() => this.setState({ renameModal: false })}
						mutation={(name) => ({
							variables: { id: program._id, name },
							mutation: gql`
								mutation renameProgram(
									$id: String!
									$name: String!
								) {
									programUpdateById(
										_id: $id
										record: { name: $name }
									) {
										recordId
									}
								}
							`,
							refetchQueries: [
								{
									query: ProgramsTableQuery
								},
								{
									query: ProgramDetailsQuery,
									variables: { id: program._id }
								}
							]
						})}
					/>
				)}
				{this.state.selectTeamModal && (
					<SelectTeamModal
						title={intl.formatMessage({
							id: 'programs.actions.assignProgramToTeam'
						})}
						onClose={() =>
							this.setState({ selectTeamModal: false })
						}
						selected={program.teamId}
						onSelect={async (t) => {
							try {
								const r = await client.mutate({
									variables: { id: program._id, parent: t },
									mutation: gql`
										mutation updateProgramTeamId(
											$id: String!
											$parent: String
										) {
											programUpdateById(
												_id: $id
												record: { teamId: $parent }
											) {
												recordId
											}
										}
									`,
									refetchQueries: [
										{
											query: ProgramsTableQuery
										}
									]
								})
								Utils.parseMutationResponse(r)
							} catch (e) {
								Utils.displayError(e)
							}
						}}
					/>
				)}
				{this.state.duplicateModal && (
					<NewProgramModal
						name={`${program.name} copy`}
						team={program.team}
						duplicate={program}
						onClose={(_) =>
							this.setState({ duplicateModal: false })
						}
					/>
				)}
				{this.state.newPlaylistModal && (
					<EditPlaylistDetails
						program={program}
						onClose={() =>
							this.setState({ newPlaylistModal: false })
						}
					/>
				)}
			</div>
		)
	}
}

const ProgramDetails = withApollo(injectIntl(IProgramDetails))

class ProgramDetailsFetch extends Component {
	render() {
		const program = this.props.match.params.programId
		return (
			<div>
				{program && (
					<Query
						pollInterval={config.longPollInterval}
						query={ProgramDetailsQuery}
						variables={{ id: program }}
					>
						{({ loading, error, data }) => {
							if ((!data || !data.programById) && loading) {
								return <Loading />
							}
							if (error) {
								return (
									<Alert
										message={this.props.intl.formatMessage({
											id: 'error'
										})}
										description={displayGraphQLErrors(
											error
										)}
										type="error"
										showIcon
									/>
								)
							}

							if (!data.programById) {
								return <Redirect to="/programs" />
							}

							return <ProgramDetails program={data.programById} />
						}}
					</Query>
				)}
			</div>
		)
	}
}

export default injectIntl(ProgramDetailsFetch)
