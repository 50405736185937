import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Alert, Button, Divider, Modal, Tag, Tooltip } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link, Redirect } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faInfoCircle,
	faVolumeUp,
	faUsers,
	faEdit,
	faTrash,
	faClipboard,
	faPlus,
	faSyncAlt,
	faCopy
} from '@fortawesome/free-solid-svg-icons'
import flatten from 'lodash/flatten'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'

import DeviceInfo from './DeviceInfo'
import Player from '../tracks/Player'
import Loading from '../../components/LoadingPage'
import config from '../../config'
import Title from '../../components/Title'
import TextInputModal from '../../components/TextInputModal'
import { SelectTeamModal } from '../teams/SelectTeam'
import { setPageTitle } from '../../components/Utils'
import {
	DeviceDetailsQuery,
	DevicesTableQuery,
	DeviceActionMutation
} from './Queries'
import S from '../../components/Styles'
import * as Utils from '../../components/Utils'
import * as Auth from '../../auth'
import { DeviceListDisplay } from './DevicesTable'
import Comments from '../../components/CommentsPage'
import NetworkInfo from './NetworkInfo'

class IDeviceDetails extends Component {
	state = {
		renameModal: false,
		selectTeamModal: false,
		duplicateModal: false,
		networkModal: false
	}

	/**
	 * This deviceNotReachableSince duration is displayed only if the device is disabled.
	 * @returns number The duration since the device is not reachable in days
	 */
	getDeviceNotReachableSince = () => {
		const { device } = this.props
		// If the device's ttl is never
		// then use the last connection date
		// otherwise the device has been offline for more than the ttl
		const lastConnectionDuration = moment().diff(
			device.lastConnection,
			'days'
		)
		if (device.team?.deviceTtl && device.team.deviceTtl >= 1) {
			// the device's ttl is stored in days
			return device.team.deviceTtl
		} else {
			// This condition can happen is the current device's team ttl is never
			// and the team has changed after that the device has been disabled
			// return the duration since the last connection date in days
			return lastConnectionDuration
		}
	}

	render() {
		const { device, intl, client } = this.props
		setPageTitle(intl, null, device.nickname || device.name)

		const status = Utils.deviceStatus(device)
		const sync = Utils.deviceSync(
			device,
			Auth.hasPermission('device:read:syncStatusDate')
		)
		const osLogo = Utils.osLogo(device.OS)

		let actions = []
		if (
			Auth.hasPermission('device:edit:setName') &&
			Auth.hasAccess('device:edit', device.teamId)
		) {
			actions.push({
				title: intl.formatMessage({ id: 'actions.rename' }),
				key: 'renameDevice',
				icon: faEdit,
				action: () => this.setState({ renameModal: true })
			})
		}
		if (
			Auth.hasPermission('device:edit:setTeam') &&
			Auth.hasAccess('device:edit', device.teamId)
		) {
			actions.push({
				title: this.props.intl.formatMessage({
					id: 'actions.assignToTeam'
				}),
				key: 'assignDevice',
				icon: faUsers,
				action: () => this.setState({ selectTeamModal: true })
			})
		}
		if (Auth.hasAccess('zone:add', device.teamId)) {
			actions.push({
				title: this.props.intl.formatMessage({
					id: 'devices.actions.addZone'
				}),
				key: 'addZone',
				icon: faPlus,
				disabled: device.zones.length >= device.maxZones,
				mutation: {
					variables: { device: device._id },
					mutation: gql`
						mutation createZone($device: String!) {
							zoneCreate(deviceId: $device) {
								success
								message
							}
						}
					`,
					refetchQueries: [
						{
							query: DeviceDetailsQuery,
							variables: { id: device._id }
						}
					]
				}
			})
		}
		if (
			Auth.hasAccess('zone:add', device.teamId) &&
			Auth.hasAccess('device:edit', device.teamId)
		) {
			actions.push({
				title: this.props.intl.formatMessage({
					id: 'devices.actions.duplicateZones'
				}),
				key: 'duplicateDevice',
				icon: faCopy,
				action: () => this.setState({ duplicateModal: true })
			})
		}
		if (Auth.hasAccess('device:delete', device.teamId)) {
			actions.push({
				title: this.props.intl.formatMessage({ id: 'actions.delete' }),
				key: 'deleteDevice',
				dataTest: 'delete-device',
				icon: faTrash,
				action: () => {
					Modal.confirm({
						title: intl.formatMessage(
							{ id: 'devices.actions.delete.confirm' },
							{ name: device.nickname || device.name || '' }
						),
						okText: intl.formatMessage({ id: 'yes' }),
						okType: 'danger',
						cancelText: intl.formatMessage({ id: 'no' }),
						onOk: async () => {
							try {
								// remove device
								const dr = await client.mutate({
									variables: { id: device._id },
									mutation: gql`
										mutation removeDeviceById(
											$id: String!
										) {
											removeDeviceByIdAndLogout(
												_id: $id
											) {
												success
												message
											}
										}
									`,
									refetchQueries: [
										{
											query: DevicesTableQuery
										},
										{
											query: DeviceDetailsQuery,
											variables: { id: device._id }
										}
									]
								})
								Utils.parseMutationResponse(dr)
							} catch (e) {
								Utils.displayError(e)
							}
						}
					})
				}
			})
		}

		return (
			<div>
				<Title
					title={device.nickname || device.name}
					actions={actions}
					backUrl="/devices"
					backTitle="devices"
					wideMode={this.props.wideMode}
					dataTest="main-player-menu"
				>
					<div style={S.itemDetails.status}>
						<Tooltip title={status.title}>
							<FontAwesomeIcon
								size="lg"
								fixedWidth
								icon={status.icon}
								style={{ color: status.color }}
							/>
						</Tooltip>
						<Tooltip
							title={
								<span>
									{sync.title}
									{device.forceUpdate ?
										this.props.intl.formatMessage({
											id: 'devices.info.willForceUpdate'
										})
									:	''}
								</span>
							}
						>
							{(
								Auth.hasPermission('device:edit:syncContent') &&
								Auth.hasAccess('device:edit', device.teamId)
							) ?
								<FontAwesomeIcon
									size="lg"
									fixedWidth
									icon={faSyncAlt}
									spin={sync.spin}
									style={{
										color: sync.color,
										marginLeft: 10
									}}
								/>
							:	<FontAwesomeIcon
									size="lg"
									fixedWidth
									icon={faSyncAlt}
									spin={sync.spin}
									style={{
										color: sync.color,
										marginLeft: 10
									}}
								/>
							}
						</Tooltip>
						<Tooltip
							title={
								<span>
									{device.brand} {device.model}
									<br />
									{device.OS} {device.OSVersion}
								</span>
							}
						>
							<FontAwesomeIcon
								size="lg"
								fixedWidth
								icon={osLogo}
								style={{ marginLeft: 10 }}
							/>
						</Tooltip>
					</div>
					{device.team && (
						<div style={S.itemDetails.teamPath}>
							<Link to={`/teams/${device.team._id}`}>
								<Tag color="blue" style={{ margin: 0 }}>
									{device.team.path}
								</Tag>
							</Link>
						</div>
					)}
				</Title>

				{Auth.hasPermission('device:read:zones') &&
					device.zones.length > 0 && (
						<div>
							<Divider orientation="left">
								<FontAwesomeIcon icon={faVolumeUp} />{' '}
								<FormattedMessage
									id="devices.details.zones"
									values={{ count: device.zones.length }}
								/>
							</Divider>
							{[...device.zones]
								.sort((a, b) => a.index - b.index)
								.map((z, i) => (
									<Player
										key={`${z._id}-${i}`}
										device={device}
										zone={z}
										index={i + 1}
										onlyOne={device.zones.length === 1}
									/>
								))}
						</div>
					)}

				{this.state.renameModal && (
					<TextInputModal
						initialValue={device.nickname || device.name}
						title={this.props.intl.formatMessage({
							id: 'devices.actions.renameDevice'
						})}
						actionLabel={this.props.intl.formatMessage({
							id: 'actions.rename'
						})}
						placeholder={this.props.intl.formatMessage({
							id: 'device'
						})}
						onClose={() => this.setState({ renameModal: false })}
						mutation={(name) => ({
							variables: { id: device._id, name },
							mutation: gql`
								mutation updateDeviceNickname(
									$id: MongoID!
									$name: String!
								) {
									deviceUpdateById(
										_id: $id
										record: { nickname: $name }
									) {
										recordId
									}
								}
							`
						})}
					/>
				)}
				{this.state.selectTeamModal && (
					<SelectTeamModal
						title={this.props.intl.formatMessage({
							id: 'devices.actions.assignDeviceToTeam'
						})}
						onClose={() =>
							this.setState({ selectTeamModal: false })
						}
						selected={device.teamId}
						hideNoParents
						onSelect={async (t) => {
							try {
								if (t && t !== device.teamId) {
									const r = await client.mutate({
										variables: {
											id: device._id,
											parent: t
										},
										mutation: gql`
											mutation updateDeviceTeam(
												$id: MongoID!
												$parent: String!
											) {
												deviceUpdateById(
													_id: $id
													record: { teamId: $parent }
												) {
													recordId
												}
											}
										`,
										refetchQueries: [
											{
												query: DevicesTableQuery
											}
										]
									})
									Utils.parseMutationResponse(r)
								}
							} catch (e) {
								Utils.displayError(e)
							}
						}}
					/>
				)}
				{this.state.duplicateModal && (
					<Modal
						visible={true}
						title={intl.formatMessage({
							id: 'devices.actions.duplicateZones'
						})}
						onCancel={() =>
							this.setState({ duplicateModal: false })
						}
						footer={[]}
					>
						<DeviceListDisplay
							onSelect={async (source) => {
								if (source && source._id) {
									try {
										const r = await client.mutate({
											variables: {
												source: source._id,
												destination: device._id
											},
											mutation: gql`
												mutation duplicateDevice(
													$source: String!
													$destination: String!
												) {
													duplicateDevice(
														source: $source
														destination: $destination
													) {
														success
														message
													}
												}
											`,
											refetchQueries: [
												{
													query: DevicesTableQuery
												},
												{
													query: DeviceDetailsQuery,
													variables: {
														id: device._id
													}
												}
											]
										})
										Utils.parseMutationResponse(r)
									} catch (e) {
										Utils.displayError(e)
									}
								}
								this.setState({ duplicateModal: false })
							}}
							defaultColumns={['name', 'os']}
						/>
					</Modal>
				)}
				{device.disabled && (
					<Alert
						type="warning"
						showIcon
						message={intl.formatMessage(
							{ id: 'devices.info.disabled' },
							{
								duration: moment
									.duration(
										this.getDeviceNotReachableSince(),
										'days'
									)
									.humanize()
							}
						)}
						style={{ marginBottom: 20 }}
					/>
				)}
				{device.activationLink ?
					<Alert
						type="info"
						showIcon
						message={
							<div>
								{intl.formatMessage({
									id: 'devices.info.avaitingActivation'
								})}
								{device.type === 'largo' && (
									<>
										<p style={{ margin: 10 }}>
											{device.activationLink}
										</p>
										<CopyToClipboard
											text={device.activationLink}
										>
											<Button>
												{intl.formatMessage({
													id: 'devices.info.copyTheActivationLink'
												})}
											</Button>
										</CopyToClipboard>
									</>
								)}
							</div>
						}
						style={{ marginBottom: 20 }}
					/>
				:	<React.Fragment>
						<Divider orientation="left">
							<FontAwesomeIcon icon={faInfoCircle} />{' '}
							<FormattedMessage id="devices.details.deviceInfo" />
						</Divider>
						<DeviceInfo device={device} />

						{Auth.hasPermission('device:read:network') && (
							<NetworkInfo device={device} />
						)}
					</React.Fragment>
				}

				{Auth.hasPermission('device:read:note') && (
					<React.Fragment>
						<Divider orientation="left">
							<FontAwesomeIcon icon={faClipboard} />{' '}
							<FormattedMessage id="spectreNotes" />
						</Divider>
						<Comments
							objectId={device._id}
							writePermission="device:edit:setNote"
						/>
					</React.Fragment>
				)}
			</div>
		)
	}
}

export const DeviceDetails = withApollo(injectIntl(IDeviceDetails))

class DeviceDetailsFetch extends Component {
	render() {
		const device = this.props.match.params.deviceId
		return (
			<React.Fragment>
				{device && (
					<Query
						pollInterval={config.pollInterval}
						query={DeviceDetailsQuery}
						variables={{ id: device }}
					>
						{({ loading, error, data }) => {
							if ((!data || !data.deviceById) && loading) {
								return <Loading />
							}
							if (error) {
								return (
									<Alert
										message={this.props.intl.formatMessage({
											id: 'error'
										})}
										description={Utils.displayGraphQLErrors(
											error
										)}
										type="error"
										showIcon
									/>
								)
							}

							if (!data.deviceById) {
								return <Redirect to="/devices" />
							}

							return (
								<DeviceAdditionalDetailsFetch
									device={data.deviceById}
									wideMode={this.props.wideMode}
								/>
							)
						}}
					</Query>
				)}
			</React.Fragment>
		)
	}
}

export default injectIntl(DeviceDetailsFetch)

class DeviceAdditionalDetailsFetch extends Component {
	render() {
		let { device } = this.props
		return (
			<Query
				query={gql`
					query fetchDeviceAdditionalDetails(
						$team: String!
						$programs: [String!]!
						$messagePrograms: [String!]!
					) {
						teamById(_id: $team) {
							_id
							n
							path
							messagesEnabled
							parentMessagesEnabled
							children
							deviceTtl
						}
						programs: programByIds(_ids: $programs) {
							_id
							name
							lastProgramModification
							scheduleReset
							playlists {
								_id
								name
								canForceTrack
							}
						}
						messagePrograms: messageProgramsByIds(
							_ids: $messagePrograms
						) {
							_id
							name
							updated
							schedule
							medias {
								_id
								name
								duration
							}
						}
					}
				`}
				variables={{
					team: device.teamId.toString(),
					programs: flatten(
						compact(
							uniq(
								device.zones
									.filter(
										(z) =>
											z !== null && typeof z === 'object'
									)
									.map((z) => [z.stateProgramId, z.programId])
							)
						)
					).filter((value) => value !== null),
					messagePrograms: flatten(
						compact(
							uniq(device.zones.map((z) => z.messageProgramIds))
						)
					)
				}}
			>
				{({ loading, error, data }) => {
					if (!data || loading) {
						return <Loading />
					}
					if (error) {
						return (
							<Alert
								message={this.props.intl.formatMessage({
									id: 'error'
								})}
								description={Utils.displayGraphQLErrors(error)}
								type="error"
								showIcon
							/>
						)
					}

					let zones = []
					const programs =
						data.programs ? Utils.indexById(data.programs) : []
					const msgPrograms =
						data.messagePrograms ?
							Utils.indexById(data.messagePrograms)
						:	{}
					for (let z of device.zones) {
						const zone = {
							...z,
							program:
								z.programId && programs[z.programId] ?
									programs[z.programId]
								:	z.program,
							stateProgram:
								z.stateProgramId && programs[z.stateProgramId] ?
									programs[z.stateProgramId]
								:	z.stateProgram,
							messagePrograms:
								(
									z.messageProgramIds &&
									Object.keys(msgPrograms).length > 0
								) ?
									z.messageProgramIds.map(
										(mp) => msgPrograms[mp]
									)
								:	z.messagePrograms
						}
						zones.push(zone)
					}

					const populatedDevice = {
						...device,
						team: data.teamById ? data.teamById : undefined,
						zones
					}

					return (
						<DeviceDetails
							device={populatedDevice}
							wideMode={this.props.wideMode}
						/>
					)
				}}
			</Query>
		)
	}
}
