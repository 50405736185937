import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import {
	Alert,
	Tag,
	Tabs,
	Form,
	Input,
	Slider,
	Timeline,
	Button,
	Divider
} from 'antd'
import { Link, Redirect } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faCalendarAlt,
	faHdd,
	faTrash,
	faEdit,
	faUsers,
	faClipboard,
	faCog,
	faSyncAlt,
	faCopy,
	faPlus,
	faComments
} from '@fortawesome/free-solid-svg-icons'
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons'

import Loading from '../../components/LoadingPage'
import config from '../../config'
import Title from '../../components/Title'
import S, { formItemLayoutXWide } from '../../components/Styles'
import { setPageTitle, displayGraphQLErrors } from '../../components/Utils'
import { getDateFromObjectIdString } from '../../utils/date'
import {
	MessageProgramDetailsQuery,
	MessageProgramsTableQuery,
	MessageProgramDetailsDevicesQuery
} from './Queries'
import { DeviceActionMutation } from '../devices/Queries'
import Scheduler from './Scheduler'
import * as Auth from '../../auth'
import TextInputModal from '../../components/TextInputModal'
import { SelectTeamModal } from '../teams/SelectTeam'
import { DeviceListDisplay } from '../devices/DevicesTable'
import MediaList from './MediaList'
import * as Utils from '../../components/Utils'
import NewMessageProgram from './NewMessageProgram'
import Comments from '../../components/CommentsPage'
import SelectZones from '../devices/SelectZones'

const TabPane = Tabs.TabPane
const FormItem = Form.Item

class IMessageProgramDetails extends Component {
	state = {
		renameModal: false,
		selectTeamModal: false,
		volume: this.props.messageProgram.musicVolume * 100 || 0,
		crossfadeValue: this.props.messageProgram.crossfade,
		duplicateModal: false,
		addDevicesModal: false
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.messageProgram._id !== this.props.messageProgram._id) {
			this.setState({
				volume: nextProps.messageProgram.musicVolume * 100 || 0,
				crossfadeValue: nextProps.messageProgram.crossfade
			})
		}
	}

	onCrossfadeChange = async (e) => {
		const { client, messageProgram } = this.props
		try {
			if (this.state.crossfadeValue) {
				const value = parseInt(this.state.crossfadeValue, 10)
				const r = await client.mutate({
					variables: {
						id: messageProgram._id,
						crossfade: value,
						date: new Date()
					},
					mutation: gql`
						mutation updateMessageProgramCrossfade(
							$id: String!
							$crossfade: Float!
							$date: Date!
						) {
							messageProgramUpdateById(
								_id: $id
								record: {
									crossfade: $crossfade
									updated: $date
								}
							) {
								recordId
							}
						}
					`,
					refetchQueries: [
						{
							query: MessageProgramDetailsQuery,
							variables: { id: messageProgram._id }
						}
					]
				})
				Utils.parseMutationResponse(r)
			}
		} catch (e) {
			Utils.displayError(e)
		}
	}

	syncAll = async (zones) => {
		for (let zone of zones) {
			try {
				const r = await this.props.client.mutate(
					DeviceActionMutation(zone.device, zone, 'sync')
				)
				Utils.parseMutationResponse(r)
			} catch (e) {
				Utils.displayError(e)
			}
		}
	}

	sendVolume = async () => {
		const { client, messageProgram } = this.props
		try {
			const r = await client.mutate({
				variables: {
					id: messageProgram._id,
					musicVolume: this.state.volume / 100,
					date: new Date()
				},
				mutation: gql`
					mutation updateMessageProgramMusicVolume(
						$id: String!
						$musicVolume: Float!
						$date: Date!
					) {
						messageProgramUpdateById(
							_id: $id
							record: {
								musicVolume: $musicVolume
								updated: $date
							}
						) {
							recordId
						}
					}
				`,
				refetchQueries: [
					{
						query: MessageProgramDetailsQuery,
						variables: { id: messageProgram._id }
					}
				]
			})
			Utils.parseMutationResponse(r)
		} catch (e) {
			Utils.displayError(e)
		}
	}

	render() {
		const { messageProgram, intl, client } = this.props
		setPageTitle(intl, null, messageProgram.name)

		let actions = []
		if (
			Auth.hasPermission('messageProgram:edit:setName') &&
			Auth.hasAccess('messageProgram:edit', messageProgram.teamId)
		) {
			actions.push({
				title: intl.formatMessage({ id: 'actions.rename' }),
				key: 'renameProgram',
				icon: faEdit,
				action: () => this.setState({ renameModal: true })
			})
		}
		if (Auth.hasAccess('messageProgram:add', messageProgram.teamId)) {
			actions.push({
				title: intl.formatMessage({ id: 'programs.actions.duplicate' }),
				key: 'duplicate',
				icon: faCopy,
				action: () => this.setState({ duplicateModal: true })
			})
		}
		if (
			Auth.hasPermission('messageProgram:edit:setTeam') &&
			Auth.hasAccess('messageProgram:edit', messageProgram.teamId)
		) {
			actions.push({
				title: intl.formatMessage({ id: 'actions.assignToTeam' }),
				key: 'assignProgram',
				icon: faUsers,
				action: () => this.setState({ selectTeamModal: true })
			})
		}
		if (Auth.hasAccess('messageProgram:delete', messageProgram.teamId)) {
			actions.push({
				title: intl.formatMessage({ id: 'actions.delete' }),
				key: 'deleteProgram',
				icon: faTrash,
				confirm: intl.formatMessage(
					{ id: 'programs.actions.delete.confirm' },
					{ name: messageProgram.name }
				),
				mutation: {
					variables: { id: messageProgram._id },
					mutation: gql`
						mutation removeMessageProgram($id: String!) {
							messageProgramRemoveById(id: $id) {
								success
								message
							}
						}
					`,
					refetchQueries: [
						{
							query: MessageProgramsTableQuery
						},
						{
							query: MessageProgramDetailsQuery,
							variables: { id: messageProgram._id }
						}
					],
					awaitRefetchQueries: true
				}
			})
		}

		const messageProgramCreationDate = getDateFromObjectIdString(
			messageProgram._id
		)

		return (
			<div>
				<Title
					title={messageProgram.name}
					actions={actions}
					backTitle="messagePrograms"
					backUrl="/messages"
				>
					<div style={S.itemDetails.teamPath}>
						{messageProgram.team && (
							<Link to={`/teams/${messageProgram.team._id}`}>
								<Tag color="blue">
									{messageProgram.team.path}
								</Tag>
							</Link>
						)}
					</div>
				</Title>

				<Tabs>
					<TabPane
						tab={
							<span>
								<FontAwesomeIcon icon={faCalendarAlt} />{' '}
								<FormattedMessage id="schedule" />
							</span>
						}
						key="1"
					>
						<Scheduler
							messageProgram={messageProgram}
							editable={
								Auth.hasAccess(
									'messageProgram:edit',
									messageProgram.teamId
								) &&
								Auth.hasPermission(
									'messageProgram:edit:setSchedule'
								)
							}
							save={async (events, mediaIds) =>
								await client.mutate({
									variables: {
										id: messageProgram._id,
										schedule: JSON.stringify(events),
										mediaIds,
										date: new Date()
									},
									mutation: gql`
										mutation editMessageProgram(
											$id: String!
											$schedule: String
											$mediaIds: [String]
											$date: Date!
										) {
											messageProgramUpdateById(
												_id: $id
												record: {
													updated: $date
													mediaIds: $mediaIds
													schedule: $schedule
												}
											) {
												recordId
											}
										}
									`,
									refetchQueries: [
										{
											query: MessageProgramDetailsQuery,
											variables: {
												id: messageProgram._id
											}
										}
									]
								})
							}
						/>
					</TabPane>
					<TabPane
						tab={
							<span>
								<FontAwesomeIcon icon={faCommentAlt} />{' '}
								<FormattedMessage id="messages" /> (
								{messageProgram.medias ?
									messageProgram.medias.length
								:	0}
								)
							</span>
						}
						key="2"
					>
						<MediaList
							messageProgram={messageProgram}
							showActions
						/>
					</TabPane>
					{Auth.hasAccess('device:browse', 'all') && (
						<TabPane
							tab={
								<span>
									<FontAwesomeIcon icon={faHdd} />{' '}
									<FormattedMessage id="devices" />
								</span>
							}
							key="3"
						>
							<Query
								pollInterval={config.longPollInterval}
								query={MessageProgramDetailsDevicesQuery}
								variables={{ id: messageProgram._id }}
							>
								{({ loading, error, data }) => {
									if (data && data.messageProgramById) {
										return (
											<React.Fragment>
												{Auth.hasPermission(
													'device:edit:syncContent'
												) &&
													Auth.hasAccess(
														'device:edit',
														messageProgram.teamId
													) && (
														<Button
															style={{
																marginBottom: 10
															}}
															onClick={() =>
																this.syncAll(
																	data
																		.messageProgramById
																		.zones
																)
															}
														>
															<FontAwesomeIcon
																icon={faSyncAlt}
															/>
															&nbsp;
															<FormattedMessage id="programs.details.syncAllDevices" />
														</Button>
													)}
												{Auth.hasPermission(
													'zone:edit:setProgram'
												) && (
													<Button
														style={{
															marginBottom: 10,
															marginLeft: 10
														}}
														onClick={() =>
															this.setState({
																addDevicesModal: true
															})
														}
													>
														<FontAwesomeIcon
															icon={faPlus}
														/>
														&nbsp;
														<FormattedMessage id="programs.details.addDevices" />
													</Button>
												)}
												<DeviceListDisplay
													data={data.messageProgramById.zones.map(
														(z) => {
															if (
																z.device &&
																z.device._id
															) {
																return {
																	...z.device,
																	zone: {
																		_id: z._id,
																		name: z.name
																	}
																}
															}
															return null
														}
													)}
													defaultColumns={[
														'name',
														'status',
														'sync',
														'os',
														'zone'
													]}
													link
												/>
												{this.state.addDevicesModal && (
													<SelectZones
														title={intl.formatMessage(
															{
																id: 'programs.details.addDevices.title'
															},
															{
																program:
																	messageProgram.name
															}
														)}
														selected={data.messageProgramById.zones.map(
															(z) => z._id
														)}
														teams={[
															messageProgram.teamId
														]}
														onClose={(_) =>
															this.setState({
																addDevicesModal: false
															})
														}
														property="messagePrograms"
														query={gql`
															query fetchTeamsForMessageProgram(
																$teams: [String!]!
															) {
																teams: teamByIds(
																	_ids: $teams
																) {
																	_id
																	n
																	messagesEnabled
																	parentMessagesEnabled
																	thirdPartyDevicesEnabled
																	parentThirdPartyDevicesEnabled
																	devices {
																		_id
																		name
																		nickname
																		type
																		zones {
																			_id
																			name
																			messagePrograms {
																				_id
																				name
																			}
																		}
																	}
																}
															}
														`}
														onSave={async (
															zoneIds,
															removeZoneIds,
															sync
														) => {
															try {
																const r =
																	await client.mutate(
																		{
																			variables:
																				{
																					id: messageProgram._id,
																					zoneIds,
																					removeZoneIds,
																					sync
																				},
																			mutation: gql`
																				mutation messageProgramAssignToDevices(
																					$id: String!
																					$zoneIds: [String]!
																					$removeZoneIds: [String]
																					$sync: Boolean
																				) {
																					messageProgramAssignToDevices(
																						id: $id
																						zoneIds: $zoneIds
																						removeZoneIds: $removeZoneIds
																						sync: $sync
																					) {
																						success
																						message
																					}
																				}
																			`,
																			refetchQueries:
																				[
																					{
																						query: MessageProgramDetailsDevicesQuery,
																						variables:
																							{
																								id: messageProgram._id
																							}
																					}
																				],
																			awaitRefetchQueries: true
																		}
																	)
																Utils.parseMutationResponse(
																	r
																)
															} catch (e) {
																Utils.displayError(
																	e
																)
															}
														}}
														isRowDisabled={(
															record
														) => {
															return (
																record.type !==
																	'drax' ||
																!record.messageProgramsEnabled
															)
														}}
													/>
												)}
											</React.Fragment>
										)
									}
									if (error) {
										return (
											<Alert
												message={this.props.intl.formatMessage(
													{ id: 'error' }
												)}
												description={displayGraphQLErrors(
													error
												)}
												type="error"
												showIcon
											/>
										)
									}
									if (loading) {
										return <Loading />
									}
									return null
								}}
							</Query>
						</TabPane>
					)}
					<TabPane
						tab={
							<span>
								<FontAwesomeIcon icon={faCog} />{' '}
								<FormattedMessage id="settings" />
							</span>
						}
						key="4"
					>
						<Form>
							<FormItem
								{...formItemLayoutXWide}
								label={intl.formatMessage({
									id: 'playlists.details.crossfade'
								})}
							>
								<Input
									key={`xfade`}
									addonAfter={intl.formatMessage({
										id: 'playlists.details.crossfade.seconds'
									})}
									placeholder="3"
									onPressEnter={this.onCrossfadeChange}
									onChange={(e) =>
										this.setState({
											crossfadeValue: e.target.value
										})
									}
									onBlur={this.onCrossfadeChange}
									value={this.state.crossfadeValue}
									className="rightAlignInput"
									disabled={
										!(
											Auth.hasPermission(
												'messageProgram:edit:setCrossfade'
											) &&
											Auth.hasAccess(
												'messageProgram:edit',
												messageProgram.teamId
											)
										)
									}
								/>
							</FormItem>
							<FormItem
								{...formItemLayoutXWide}
								label={intl.formatMessage({
									id: 'messagePrograms.details.volume'
								})}
							>
								<Slider
									onAfterChange={this.sendVolume}
									onChange={(v) =>
										this.setState({ volume: v })
									}
									value={this.state.volume}
									min={0}
									max={100}
									disabled={
										!(
											Auth.hasPermission(
												'messageProgram:edit:setMusicVolume'
											) &&
											Auth.hasAccess(
												'messageProgram:edit',
												messageProgram.teamId
											)
										)
									}
								/>
							</FormItem>
						</Form>
					</TabPane>
					{Auth.hasPermission('messageProgram:read:note') && (
						<TabPane
							tab={
								<span>
									<FontAwesomeIcon icon={faClipboard} />{' '}
									<FormattedMessage id="spectreNotes" />
								</span>
							}
							key="5"
						>
							<Timeline mode="left" style={{ marginTop: 20 }}>
								<Timeline.Item
									color="green"
									label={messageProgramCreationDate.toLocaleString()}
								>
									Created
								</Timeline.Item>
								<Timeline.Item
									label={new Date(
										messageProgram.updated
									).toLocaleString()}
								>
									Last updated
								</Timeline.Item>
							</Timeline>
							<Divider orientation="left">
								<FontAwesomeIcon icon={faComments} /> Comments
							</Divider>
							<Comments
								objectId={messageProgram._id}
								writePermission="messageProgram:edit:setNote"
							/>
						</TabPane>
					)}
				</Tabs>

				{this.state.renameModal && (
					<TextInputModal
						initialValue={messageProgram.name}
						title={intl.formatMessage({
							id: 'programs.actions.renameProgram'
						})}
						actionLabel={intl.formatMessage({
							id: 'actions.rename'
						})}
						placeholder={intl.formatMessage({
							id: 'messageProgram'
						})}
						onClose={() => this.setState({ renameModal: false })}
						mutation={(name) => ({
							variables: {
								id: messageProgram._id,
								name,
								date: new Date()
							},
							mutation: gql`
								mutation updateMessageProgramName(
									$id: String!
									$name: String!
									$date: Date!
								) {
									messageProgramUpdateById(
										_id: $id
										record: { name: $name, updated: $date }
									) {
										recordId
									}
								}
							`,
							refetchQueries: [
								{
									query: MessageProgramsTableQuery
								},
								{
									query: MessageProgramDetailsQuery,
									variables: { id: messageProgram._id }
								}
							]
						})}
					/>
				)}
				{this.state.selectTeamModal && (
					<SelectTeamModal
						title={intl.formatMessage({
							id: 'programs.actions.assignProgramToTeam'
						})}
						onClose={() =>
							this.setState({ selectTeamModal: false })
						}
						selected={messageProgram.teamId}
						onSelect={async (t) => {
							try {
								const r = await client.mutate({
									variables: {
										id: messageProgram._id,
										parent: t,
										date: new Date()
									},
									mutation: gql`
										mutation updateMessageProgramTeam(
											$id: String!
											$parent: String
											$date: Date!
										) {
											messageProgramUpdateById(
												_id: $id
												record: {
													teamId: $parent
													updated: $date
												}
											) {
												recordId
											}
										}
									`,
									refetchQueries: [
										{
											query: MessageProgramsTableQuery
										}
									]
								})
								Utils.parseMutationResponse(r)
							} catch (e) {
								Utils.displayError(e)
							}
						}}
					/>
				)}
				{this.state.duplicateModal && (
					<NewMessageProgram
						name={`${messageProgram.name} copy`}
						team={messageProgram.team}
						duplicate={messageProgram}
						onClose={(_) =>
							this.setState({ duplicateModal: false })
						}
					/>
				)}
			</div>
		)
	}
}

const MessageProgramDetails = withApollo(injectIntl(IMessageProgramDetails))

class MessageProgramDetailsFetch extends Component {
	render() {
		const messageProgram = this.props.match.params.messageProgramId

		return (
			<div>
				{messageProgram && (
					<Query
						pollInterval={config.longPollInterval}
						query={MessageProgramDetailsQuery}
						variables={{ id: messageProgram }}
					>
						{({ loading, error, data }) => {
							if (
								(!data || !data.messageProgramById) &&
								loading
							) {
								return <Loading />
							}
							if (error) {
								return (
									<Alert
										message={this.props.intl.formatMessage({
											id: 'error'
										})}
										description={displayGraphQLErrors(
											error
										)}
										type="error"
										showIcon
									/>
								)
							}

							if (!data.messageProgramById) {
								return <Redirect to="/messages" />
							}

							return (
								<MessageProgramDetails
									messageProgram={data.messageProgramById}
								/>
							)
						}}
					</Query>
				)}
			</div>
		)
	}
}

export default injectIntl(MessageProgramDetailsFetch)
