import React, { Component } from 'react'
import { injectIntl, FormattedDate } from 'react-intl'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import Table from '../../components/TableV2'
import { setPageTitle } from '../../components/Utils'
import { getDateFromObjectIdString } from '../../utils/date'
import EditSample from './EditSample'

class ISamplesListDisplay extends Component {
	columns = {
		updated: {
			accessor: (sample) => sample.updated,
			queryProps: ['updated'],
			name: this.props.intl.formatMessage({
				id: 'samples.table.columns.updated'
			}),
			render: (sample) =>
				!!sample.updated ?
					<FormattedDate
						value={sample.updated}
						year="numeric"
						month="2-digit"
						day="2-digit"
					/>
				:	'',
			type: 'Date'
		},
		created: {
			accessor: (sample) => sample._id,
			queryProps: ['_id'],
			name: this.props.intl.formatMessage({
				id: 'samples.table.columns.created'
			}),
			render: (sample) => (
				<FormattedDate
					value={getDateFromObjectIdString(sample._id)}
					year="numeric"
					month="2-digit"
					day="2-digit"
				/>
			),
			type: 'Date'
		}
	}

	render() {
		const {
			redirect,
			link,
			match,
			selectedId,
			onSelect,
			defaultColumns,
			defaultMobileColumns,
			storageKey,
			title,
			actions,
			data
		} = this.props
		let sel = null
		if (selectedId) {
			sel = selectedId
		} else if (match && match.params && match.params.sampleId) {
			sel = match.params.sampleId
		}

		return (
			<Table
				rowKey="_id"
				columns={this.columns}
				defaultColumns={defaultColumns}
				defaultMobileColumns={defaultMobileColumns}
				link={link}
				path="/samples"
				teamProp={(record) => record.team}
				nameProp={(record) => record.name}
				nameQueryProps={['name']}
				searchProps={(record) => [record.name]}
				selectedId={sel}
				redirect={redirect}
				onSelect={onSelect}
				storageKey={storageKey}
				match={match}
				queryType="samples"
				title={title}
				actions={actions}
				data={data}
			/>
		)
	}
}

export const SamplesListDisplay = injectIntl(ISamplesListDisplay)

class SamplesList extends Component {
	state = {
		newSampleModal: false
	}

	render() {
		const { match, intl } = this.props
		if (!match.params || !match.params.sampleId) {
			setPageTitle(intl, 'samples')
		}

		let actions = [
			{
				title: intl.formatMessage({ id: 'samples.actions.newSample' }),
				key: 'newSample',
				icon: faPlus,
				action: () => this.setState({ newSampleModal: true })
			}
		]

		return (
			<React.Fragment>
				<SamplesListDisplay
					actions={actions}
					title={intl.formatMessage({ id: 'samples.table.title' })}
					match={match}
					redirect
					defaultColumns={['name', 'team']}
					defaultMobileColumns={['name']}
					extraQueryProps={[]}
					storageKey="samplesList"
				/>
				{this.state.newSampleModal && (
					<EditSample
						onClose={() => this.setState({ newSampleModal: false })}
					/>
				)}
			</React.Fragment>
		)
	}
}

export default injectIntl(SamplesList)
