import gql from 'graphql-tag'

export const SamplesTableQuery = gql`
	query SamplesTableQuery {
		samples {
			_id
			name
			team {
				_id
				path
				n
			}
		}
	}
`

export const SampleDetailsQuery = gql`
	query fetchSampleDetails($id: String!) {
		sampleById(_id: $id) {
			_id
			name
			teamId
			team {
				_id
				path
				n
			}
			tracks {
				trackId
				start
				end
			}
			trackObjects {
				_id
				title
				artist
				duration
				artwork
				comment
			}
			playlist {
				_id
				n
				created
				path
				a
			}
			playlistId
			updated
			showTrackNotes
			reviews {
				_id
				email
				name
				comment
				tracks {
					trackId
					rating
					comment
				}
				updated
			}
		}
	}
`
